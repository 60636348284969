// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-form-js": () => import("./../../../src/pages/DataForm.js" /* webpackChunkName: "component---src-pages-data-form-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-genitori-js": () => import("./../../../src/pages/genitori.js" /* webpackChunkName: "component---src-pages-genitori-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pediatra-camilla-js": () => import("./../../../src/pages/pediatraCamilla.js" /* webpackChunkName: "component---src-pages-pediatra-camilla-js" */),
  "component---src-pages-pediatra-js": () => import("./../../../src/pages/pediatra.js" /* webpackChunkName: "component---src-pages-pediatra-js" */),
  "component---src-pages-pediatra-videos-camilla-js": () => import("./../../../src/pages/pediatra/videosCamilla.js" /* webpackChunkName: "component---src-pages-pediatra-videos-camilla-js" */),
  "component---src-pages-pediatra-videos-js": () => import("./../../../src/pages/pediatra/videos.js" /* webpackChunkName: "component---src-pages-pediatra-videos-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-termini-di-servizio-js": () => import("./../../../src/pages/termini-di-servizio.js" /* webpackChunkName: "component---src-pages-termini-di-servizio-js" */),
  "component---src-templates-pediatra-videos-index-camilla-js": () => import("./../../../src/templates/pediatra/videos/indexCamilla.js" /* webpackChunkName: "component---src-templates-pediatra-videos-index-camilla-js" */),
  "component---src-templates-pediatra-videos-index-js": () => import("./../../../src/templates/pediatra/videos/index.js" /* webpackChunkName: "component---src-templates-pediatra-videos-index-js" */)
}

